export default {
  app: {
    merchant: {
      settingsData: false,
      updateSettings: false,
      loading: false,
      error: false
    },
    paymentStore: {
      checkout: false,
      refundOrder: false,
      storedCards: false,
      refundOrderbyId: false,
      newPayment: false,
      searchDetails: {
        from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        to: new Date()
      },
      pageDetails: {
        page: 0,
        limit: 10
      },
      loading: false,
      error: false
    }
  }
};
