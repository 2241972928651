import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { storedCards } from "../../../Redux/Actions/Operations";
import moment from "moment";
import { isEmpty } from "lodash";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#093f7a !important",
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const headCells = [
  {
    id: "cardNumber",
    disablePadding: true,
    label: "Card Number",
    align: "left"
  },
  {
    id: "name",
    disablePadding: true,
    label: "Card Holder Name",
    align: "center"
  },
  {
    id: "cardType",
    disablePadding: false,
    label: "Type",
    align: "center"
  },
  {
    id: "action",
    disablePadding: false,
    label: "",
    align: "center"
  }
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "normal" : "none"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StoredCards = (props) => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 2];
  const useStyles = makeStyles(styles);
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState(props.data.payment.storedCards);

  React.useEffect(() => {
    const handleBackButton = (e) => {
      navigate(`/${storeslash}/dashboard`);
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  React.useEffect(() => {
    let data = {
      storeslash: storeslash
    };
    props.storedCards(data);
  }, []);

  React.useEffect(() => {
    if (!props.data.payment.loading) {
      setRows(props.data.payment.storedCards);
    }
  }, [props]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#093f7a",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your Card has been deleted.", "success");
      }
    });
  };

  return (
    <Box className={classes.mainBox}>
      <Grid container alignItems="center" className={classes.gridBox}>
        <Typography className={classes.mainHeading}>Stored Cards</Typography>
        <Grid
          marginLeft="auto"
          gap="2rem"
          display="flex"
          className={classes.buttonBox}
        >
          <Button
            className={classes.backBtn}
            style={{ minWidth: "160px" }}
            onClick={() => {
              navigate(`/${storeslash}/dashboard`);
            }}
            variant="contained"
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <EnhancedTableHead />
          {/* <TableBody>
              {!isEmpty(rows?.data) ? (
                rows?.data.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell align="left">
                      {moment(row.created).format("ddd, D MMM YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.cardNumber}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell
                      onClick={() => handleDelete(row.id)}
                      align="center"
                      style={{ cursor: "pointer",       display: "flex",
                    alignItems: "center",
                    justifyContent: "center" }}
                    >
                      Delete {"  "}
                      <NavigateNextIcon />
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableCell align="center" colSpan={12}>
                  No stored cards found.
                </TableCell>
              )}
            </TableBody> */}
          <TableBody>
            <StyledTableRow key="id">
              <TableCell align="left">Card Number</TableCell>
              <TableCell align="center">Card Holder Name</TableCell>
              <TableCell align="center">card Type</TableCell>
              <TableCell
                onClick={() => handleDelete()}
                align="center"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                Delete {"  "}
                <NavigateNextIcon />
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

StoredCards.propTypes = {
  storedCards: PropTypes.func
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {
  storedCards
})(StoredCards);
