import { SnackbarProvider } from "notistack";
import AppView from "./Views/Layout/AppView";

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      preventDuplicate
      autoHideDuration={2500}
    >
      <AppView />
    </SnackbarProvider>
  );
}

export default App;
