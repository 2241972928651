import React from "react";
import { connect } from "react-redux";

const Checkout = () => {
  return <div>Checkout</div>;
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {})(Checkout);
