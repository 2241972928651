const styles = () => ({
  mainBtn: {
    backgroundColor: "#093f7a !important",
    fontSize: "1rem !important",
    borderRadius: ".4rem !important",
    fontFamily: "Gotham, Helvetica, Arial, sans-serif !important",
    fontWeight: "500 !important",
    letterSpacing: "0.06rem !important",
    padding: ".5rem 1.5rem 0.5rem !important",
    minWidth: "160px",
    textTransform: "capitalize !important",
    "@media (max-width: 780px)": {
      padding: ".4rem 1rem  !important",
      fontSize: "0.875rem !important",
      minWidth: "100px !important"
    }
  },
  clearBtn: {
    backgroundColor: "lightGrey !important",
    fontSize: "1rem !important",
    borderRadius: ".4rem !important",
    fontFamily: "Gotham, Helvetica, Arial, sans-serif !important",
    fontWeight: "500 !important",
    letterSpacing: "0.06rem !important",
    padding: ".5rem 1.5rem 0.5rem !important",
    minWidth: "160px !important",
    textTransform: "capitalize !important",
    "@media (max-width: 780px)": {
      padding: ".4rem 1rem !important",
      fontSize: "0.875rem !important",
      minWidth: "100px !important"
    }
  },
  backBtn: {
    backgroundColor: "grey !important",
    fontSize: "1rem !important",
    borderRadius: ".4rem !important",
    fontFamily: "Gotham, Helvetica, Arial, sans-serif !important",
    fontWeight: "500 !important",
    letterSpacing: "0.06rem !important",
    padding: ".5rem 1.5rem 0.5rem !important",
    minWidth: "160px",
    textTransform: "capitalize !important",
    color: " white !important",
    "@media (max-width: 780px)": {
      padding: ".4rem 1rem  !important",
      fontSize: "0.875rem !important",
      minWidth: "100px !important"
    }
  },
  disabledBtn: {
    backgroundColor: "lightgrey !important",
    fontSize: "1rem !important",
    borderRadius: ".4rem !important",
    fontFamily: "Gotham, Helvetica, Arial, sans-serif !important",
    fontWeight: "500 !important",
    letterSpacing: "0.06rem !important",
    padding: ".5rem 1.5rem 0.5rem !important",
    minWidth: "160px",
    textTransform: "capitalize !important",
    color: " white",
    "@media (max-width: 780px)": {
      padding: ".4rem 1rem  !important",
      fontSize: "0.875rem !important",
      minWidth: "100px !important"
    }
  },
  refundBtn: {
    backgroundColor: "#093f7a !important",
    fontSize: "1.2rem !important",
    borderRadius: "0.5rem !important",
    fontFamily: "Gotham, Helvetica, Arial, sans-serif !important",
    fontWeight: "700 !important",
    letterSpacing: "0.06rem !important",
    padding: "0.5rem 2rem !important",
    minWidth: "200px !important",
    textTransform: "capitalize !important",
    "@media (max-width: 780px)": {
      minWidth: "fit-content !important",
      padding: ".4rem 1rem  !important",
      fontSize: "1rem !important"
    }
  },
  requiredField: {
    color: "red"
  },
  mainBox: {
    padding: "2rem !important",
    minHeight: "85vh",
    position: "relative",
    "@media (max-width: 780px)": {
      padding: "1.5rem !important"
    },
    "@media (max-width: 576px)": {
      padding: "1.5rem !important"
    }
  },
  gridBox: {
    "@media (max-width: 576px)": {
      flexDirection: "column !important",
      gap: "1rem !important"
    }
  },
  buttonBox: {
    "@media (max-width: 576px)": {
      marginLeft: "unset !important",
      gap: "1.5rem !important"
    }
  },
  FieldError: {
    color: "#d32f2f",
    fontSize: "13px !important"
  },
  inputField: {
    padding: "8px !important",
    minWidth: "unset !important"
  },
  otpField: {
    padding: "10px !important",
    width: "60% !important"
  },
  mainHeading: {
    fontSize: "1.875rem !important",
    fontWeight: "700 !important",
    "@media (max-width: 1200px)": {
      fontSize: "1.4rem !important"
    },
    "@media (max-width: 780px)": {
      fontSize: "1.25rem !important"
    }
  },
  heading: {
    fontSize: "1.25rem !important",
    fontWeight: "700 !important",
    "@media (max-width: 780px)": {
      fontSize: "1rem !important"
    }
  },
  subHeading: {
    fontSize: "15px !important",
    fontWeight: "500 !important",
    "@media (max-width: 780px)": {
      fontSize: "13px !important"
    }
  },
  orderDetailCard: {
    width: "90%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 2.5rem",
    "@media (max-width: 780px)": {
      padding: "1.5rem"
    },
    "@media (max-width: 576px)": {
      padding: "1rem"
    }
    // boxShadow: `0px 0px 20px 10px rgb(0 0 0 / 10%) !important`
  },
  tableHeader: {
    fontWeight: "bold !important"
  },
  imageSize: {
    width: "30%",
    "@media (max-width: 780px)": {
      width: "50%"
    }
  },
  overLayBox: {
    position: "absolute",
    width: "30%",
    "@media (max-width: 780px)": {
      width: "85%"
    },
    "@media (max-width: 576px)": {
      width: "85%"
    },
    padding: "1.5%",
    zIndex: 999,
    cursor: "pointer",
    backgroundColor: "white",
    boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373"
  },
  supportLink: {
    position: "absolute",
    right: "3em",
    bottom: "6em"
  }
});

export default styles;
