import React from "react";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { refundOrderDetails } from "../../../Redux/Actions/Operations";
import moment from "moment";
import { getQueryParams } from "../../../Utils/commonFunctions";
import { sendOTP, refundPayment } from "../../../Operations/actions";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 1rem;
  width: 300px;
  margin-inline: auto;
  margin-top: 1.5rem;
`;

const FormTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8px !important"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          alignItems: "center !important"
        }
      }
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const OrderDetails = (props) => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 3];
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const Ref = React.useRef(null);
  const navigate = useNavigate();
  const TIMEOUT = process.env.REACT_APP_OTP_TIMEOUT;
  const REFUND_ID = getQueryParams("refundId");
  const [otpDetails, setOTPDetails] = React.useState("");
  const [refundDetail, setRefundDetail] = React.useState(
    props.data.payment.refundOrderbyId
  );
  const [value, setValue] = React.useState(0);
  const [totalSum, setTotalSum] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [showbtn, setShowResendOTPBtn] = React.useState(false);
  const [timer, setTimer] = React.useState("");

  const handleOTPChange = (newValue) => {
    setOtp(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setOtp("");
      clearTimer(getDeadTime());
      Swal.fire({
        title: "Payment cancelled!",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#093f7a"
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + parseInt(TIMEOUT));
    return deadline;
  };

  React.useEffect(() => {
    if (timer == "00:00") {
      setShowResendOTPBtn(true);
    }
  }, [timer]);

  const callRefundedData = () => {
    let data = {
      refund_id: REFUND_ID,
      storeslash: storeslash
    };
    props.refundOrderDetails(data);
  };
  React.useEffect(() => {
    callRefundedData();
  }, []);

  React.useEffect(() => {
    if (refundDetail !== props.data.payment.refundOrderbyId) {
      setRefundDetail(props.data.payment.refundOrderbyId);
    }
  }, [props]);

  const formatOTP = (otp) => {
    if (otp) return otp.replace(/(\d)(?=\d)/g, "$1-");
  };

  const resentOTP = () => {
    getOTPFunc();
    setOpen(false);
    setOtp("");
    clearTimer(getDeadTime());
  };

  const getOTPFunc = () => {
    const payload = {
      storeslash: storeslash,
      amount: refundDetail.orderdetail.total_amount
    };
    Swal.fire({
      title: "Sending one time code...",
      html: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    props.sendOTP(
      payload,
      (res) => {
        Swal.close();
        if (res.status == 1) {
          handleOpen();
          clearTimer(getDeadTime());
          setOTPDetails(res.response);
        }
      },
      (err) => {
        Swal.close();
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
          allowOutsideClick: false,
          confirmButtonColor: "#093f7a"
        });
      }
    );
  };

  const refundPaymentFun = () => {
    setOpen(false);
    clearTimer(getDeadTime());
    const data = {
      amount: refundDetail.orderdetail.total_amount,
      otp: otp,
      uuid: otpDetails.uuid,
      storeslash: storeslash,
      refund_id: refundDetail.orderdetail.id,
      order_id: refundDetail.orderdetail.order_id
    };

    Swal.fire({
      title: "Making Payment...",
      html: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    props.refundPayment(
      data,
      (res) => {
        Swal.close();
        if (res.status == "error") {
          Swal.fire({
            title: "Error!",
            text: res.desc,
            icon: "error",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Close",
            allowOutsideClick: false
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: res.desc,
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            confirmButtonColor: "#093f7a"
          }).then((result) => {
            if (result.isConfirmed) {
              callRefundedData();
            }
          });
        }
      },
      (err) => {
        Swal.close();
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
          allowOutsideClick: false,
          confirmButtonColor: "#093f7a"
        });
      }
    );
  };

  React.useEffect(() => {
    const total = refundDetail.orderdetail?.items.reduce(
      (acc, row) => acc + +row.refund_item_total,
      0
    );
    setTotalSum(total);
  }, [refundDetail]);

  return (
    <Box className={classes.mainBox}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography className={classes.mainHeading}>
          Refund Orders / Order Detail
        </Typography>
        <Grid marginLeft="auto" gap="1.5rem" display="flex">
          <Button
            className={classes.backBtn}
            style={{ minWidth: "160px" }}
            onClick={() => navigate(-1)}
            variant="contained"
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {/* customer details card */}
      <div
        style={{
          marginTop: "1rem"
        }}
      >
        <Typography className={classes.heading}>Customer Detail</Typography>
        <Card className={classes.orderDetailCard}>
          {refundDetail && (
            <Grid container display="flex" justifyContent="space-between">
              <Grid item xs={12} sm={4}>
                <Typography className={classes.tableHeader}>{`${
                  refundDetail.customer.first_name
                } ${" "} ${refundDetail.customer.last_name}`}</Typography>
                <Typography>{refundDetail.customer.email}</Typography>
                <Typography>{refundDetail.customer.phone}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <Typography className={classes.tableHeader}>
                  Billing Address:{" "}
                </Typography>
                <Typography>
                  {refundDetail.address.street_1},{" "}
                  {refundDetail.address.street_2}
                </Typography>
                <Typography>
                  {" "}
                  {refundDetail.address.city}, {refundDetail.address.state},{" "}
                  {refundDetail.address.country},
                </Typography>
                <Typography>
                  {refundDetail.address.zip},{" "}
                  {refundDetail.address.country_iso2}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
      {/* order details card */}
      <div
        style={{
          marginTop: "1rem"
        }}
      >
        <Typography className={classes.heading}>Order Summary</Typography>
        <Card className={classes.orderDetailCard}>
          {refundDetail && (
            <Grid container display="flex" justifyContent="space-between">
              <Grid item sm={12} md={3} display="flex" flexDirection="column">
                <Typography style={{ fontSize: "1.5rem" }}>
                  {refundDetail.orderdetail.currency}
                  {refundDetail.orderdetail.total_amount}
                </Typography>
                <span
                  style={{
                    background: "#093f7a",
                    color: "white",
                    padding: "8px",
                    margin: "8px 0",
                    width: "fit-content"
                  }}
                >
                  {refundDetail.orderdetail.paymentstatus.toUpperCase()}
                </span>
                <Typography>
                  <span className={classes.tableHeader}>Order date: </span>{" "}
                  {refundDetail.orderdetail.order_date}
                </Typography>
              </Grid>
              <Grid item sm={12} md={3} width="100%">
                <Grid justifyContent="space-between" display="flex">
                  <Typography className={classes.tableHeader}>
                    Shipping
                  </Typography>
                  <Typography>
                    {refundDetail.orderdetail.currency}
                    {refundDetail.orderdetail.total_shipping}
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>Tax</Typography>
                  <Typography>
                    {refundDetail.orderdetail.currency}
                    {refundDetail.orderdetail.total_tax}
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Payment Method
                  </Typography>
                  <Typography>
                    {refundDetail.orderdetail.paymentMethod}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={12} md={3} width="100%">
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Transaction Id
                  </Typography>
                  <Typography>{refundDetail.orderdetail.txnid}</Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>RRN</Typography>
                  <Typography>{refundDetail.orderdetail.rrn}</Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Approval Code
                  </Typography>
                  <Typography>
                    {refundDetail.orderdetail.approval_code}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
      {/* Refundable items  */}
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ marginTop: "2rem" }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Refundable Individual Items"
            {...a11yProps(0)}
            style={{ maxWidth: "fit-content", textTransform: "none" }}
          />
          {refundDetail && refundDetail.orderdetail.refundAction == "1" && (
            <Grid
              container
              alignItems="center"
              justifyContent="end"
              marginBottom="1rem"
            >
              <Button
                variant="contained"
                className={classes.mainBtn}
                style={{ minWidth: "160px" }}
                onClick={() => getOTPFunc()}
              >
                Refund Payment
              </Button>
            </Grid>
          )}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead style={{ background: "lightgrey" }}>
              <TableRow>
                <TableCell className={classes.tableHeader}>Item</TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  SKU
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Quantity Ordered
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Unit Price {refundDetail && refundDetail.orderdetail.currency}
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Units to refund
                </TableCell>
                <TableCell align="right" className={classes.tableHeader}>
                  Total refundable amount{" "}
                  {refundDetail && refundDetail.orderdetail.currency}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refundDetail &&
                refundDetail.orderdetail.items &&
                refundDetail.orderdetail?.items.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell align="center">{row?.sku}</TableCell>
                    <TableCell align="center">{row?.qtyordered}</TableCell>
                    <TableCell align="center">{row?.unitprice}</TableCell>
                    <TableCell align="center">{row?.quantity_refunded}</TableCell>
                    <TableCell align="right">
                      {row?.refund_item_total}
                    </TableCell>
                  </TableRow>
                ))}
              {totalSum && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="right">
                    Total: {totalSum.toFixed(2)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      {/* refund details card */}
      {!isEmpty(refundDetail.refundeddetail) && (
        <div
          style={{
            marginTop: "1rem"
          }}
        >
          <Typography className={classes.heading}>Refunded Details</Typography>
          <Card className={classes.orderDetailCard}>
            <Grid container>
              <Grid item sm={12} md={3} width="100%">
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Transaction Id
                  </Typography>
                  <Typography>{refundDetail.refundeddetail.txnid}</Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>RRN</Typography>
                  <Typography>{refundDetail.refundeddetail.rrn}</Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Approval Code
                  </Typography>
                  <Typography>
                    {refundDetail.refundeddetail.approval_code}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={12} md={1} width="100%"></Grid>
              <Grid item sm={12} md={3} width="100%">
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Payment Method
                  </Typography>
                  <Typography>
                    {refundDetail.refundeddetail.payment_method}
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>Date</Typography>
                  <Typography>
                    {moment(refundDetail.refundeddetail.refunded_date).format(
                      "ddd, D MMM YYYY hh:mm A"
                    )}
                  </Typography>
                </Grid>
                <Grid display="flex" justifyContent="space-between">
                  <Typography className={classes.tableHeader}>
                    Status
                  </Typography>
                  <Typography>{refundDetail.refundeddetail.status}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}
      <ThemeProvider theme={FormTheme}>
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ alignItems: "center" }}
        >
          <DialogTitle className={classes.mainHeading}>
            One Time Code Verification
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: "center" }}>
              Enter the verification code we sent to{" "}
              <span className={classes.tableHeader}>{otpDetails.emailId}</span>{" "}
              and{" "}
              <span className={classes.tableHeader}>
                {otpDetails.phoneNumber}.
              </span>
            </DialogContentText>
            <MuiOtpInputStyled
              value={otp}
              onChange={handleOTPChange}
              length={6}
            />
          </DialogContent>
          <Grid item xs={2} align="center">
            Didn't receive code yet?
          </Grid>
          {!showbtn && (
            <Grid item xs={2} align="center">
              Please wait{" "}
              <span
                style={{
                  color: "#093f7a",
                  fontSize: "1rem",
                  fontWeight: "500"
                }}
              >
                {timer}
              </span>{" "}
              seconds to resend code
            </Grid>
          )}
          <Button
            onClick={resentOTP}
            disabled={!showbtn}
            style={{ textTransform: "none" }}
          >
            Resend Code
          </Button>
          <DialogActions>
            <Button
              variant="contained"
              onClick={refundPaymentFun}
              className={
                otp.length != 6 ? classes.disabledBtn : classes.mainBtn
              }
              disabled={otp == "" || otp.length != 6}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.backBtn}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Box>
  );
};

OrderDetails.propTypes = {
  refundOrderDetails: PropTypes.func,
  sendOTP: PropTypes.func,
  refundPayment: PropTypes.func
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {
  refundOrderDetails,
  sendOTP,
  refundPayment
})(OrderDetails);
