import * as types from "./ActionTypes";
import * as urls from "../../Utils/urls";
import { post, get, put } from "./httpMethods";

export const refundOrder = (data) => (dispatch) => {
  dispatch({ type: types.REFUND_ORDER_API_CALL });
  get({
    url: urls.REFUND_ORDER_URL.replace(":d", data.storeslash),
    success: types.REFUND_ORDER_API_CALL_SUCCESS,
    failure: types.REFUND_ORDER_API_CALL_ERROR,
    dispatch,
    body: data
  });
};

export const refundOrderDetails = (body) => (dispatch) => {
  dispatch({ type: types.REFUND_ORDER_DETAILS_API_CALL });
  get({
    url: urls.REFUND_ORDER_DETAILS_URL.replace(":d", body.storeslash),
    success: types.REFUND_ORDER_DETAILS_API_CALL_SUCCESS,
    failure: types.REFUND_ORDER_DETAILS_API_CALL_ERROR,
    dispatch,
    body: body
  });
};

export const getSettingDetails = (storeslash) => (dispatch) => {
  dispatch({ type: types.GET_SETTINGS_API_CALL });
  get({
    url: urls.GET_SETTINGS_URL.replace(":d", storeslash),
    success: types.GET_SETTINGS_API_CALL_SUCCESS,
    failure: types.GET_SETTINGS_API_CALL_ERROR,
    dispatch,
    body: false
  });
};

export const updateSearchDate = (data) => ({
  type: types.SEARCH_DATE,
  data
});

export const updatePagination = (data) => ({
  type: types.PAGINATION,
  data
});

export const storedCards = (data) => (dispatch) => {
  dispatch({ type: types.STORED_CARDS_API_CALL });
  get({
    url: urls.REFUND_ORDER_URL.replace(":d", data.storeslash),
    success: types.STORED_CARDS_API_CALL_SUCCESS,
    failure: types.STORED_CARDS_API_CALL_ERROR,
    dispatch,
    body: data
  });
};
