const url = process.env.REACT_APP_API_BASE_PATH;

export const REFUND_ORDER_URL = `${url}/:d/refundorders`;

export const REFUND_ORDER_DETAILS_URL = `${url}/:d/refundorderdetails`;

export const PAYMENT_URL = `${url}/:d/payment`;

export const SEND_OTP_URL = `${url}/:d/sendOtp`;

export const REFUND_PAYMENT_URL = `${url}/:d/refundpayment`;

export const VALIDATE_KEYS_URL = `${url}/:d/validatekeys`;

export const GET_SETTINGS_URL = `${url}/:d/getSetting`;

export const UPDATE_LINK_SETTINGS_URL = `${url}/:d/updatesetting`;
