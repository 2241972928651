import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Select,
  Tooltip,
  InputAdornment
} from "@mui/material";
import { Box } from "@mui/system";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import Validations from "../../../Utils/formValidation";
import {
  validateKeys,
  updateSettingDetails
} from "../../../Operations/actions";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import HelpIcon from "@mui/icons-material/Help";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getSettingDetails } from "../../../Redux/Actions/Operations";
import { ReactComponent as Logo } from "../../../Assets/Images/ValorLogo.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";

const FormTheme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          width: "24px",
          height: "20px"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0",
          fontSize: "0.85rem"
        }
      }
    }
  }
});

const initialFieldValues = {
  enable: "1",
  title: "",
  apiId: "",
  apiKey: "",
  epi: "",
  authToken: "1",
  sandbox: "1",
  vault: "0",
  paymentMethod: "authorize_capture",
  surchargeMode: "0",
  surchargeType: "",
  surchargeLabel: "",
  surchargePercent: "",
  flatRate: "",
  avs: "none",
  acceptedCards: []
};

const ExistingMerchant = (props) => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 2];
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const formikRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValues, setInitialValues] = React.useState({
    ...initialFieldValues
  });
  const [showPassword, setShowPassword] = React.useState({
    value: false,
    fieldId: ""
  });

  const SettingsFlag =
    location.pathname === "/" + storeslash + "/change-settings" ? true : false;

  const handleNavigation = () => {
    navigate("/" + storeslash + "/dashboard");
  };

  React.useEffect(() => {
    if (SettingsFlag) props.getSettingDetails(storeslash);
  }, [SettingsFlag]);

  function showPasswordFun(targetID) {
    let data = { ...showPassword };
    var x = document.getElementById(targetID);
    if (x.type === "password") {
      x.type = "text";
      data.value = true;
      data.fieldId = targetID;
    } else {
      x.type = "password";
      data.value = false;
      data.fieldId = targetID;
    }
    setShowPassword(data);
  }

  const cards = [
    { name: "American Express", value: "AMEX" },
    { name: "Visa", value: "VISA" },
    { name: "MasterCard", value: "MC" },
    { name: "Discover", value: "DISCOVER" },
    { name: "Diners", value: "DINERS" },
    { name: "JCB", value: "JCB" }
  ];

  const handleSaveSettings = (body) => {
    Swal.fire({
      title: "Keys validated...",
      html: "Saving your settings...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    props.updateSettingDetails(
      body,
      (res) => {
        Swal.close();
        if (res.status == "success") {
          Swal.fire({
            title: "Success!",
            text: res.mseg,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#093f7a"
          }).then((result) => {
            if (result.isConfirmed) {
              handleNavigation();
            }
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: res.mseg,
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#093f7a"
          });
        }
      },
      (err) => {
        Swal.close();
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
          allowOutsideClick: false,
          confirmButtonColor: "#093f7a"
        });
      }
    );
  };

  const checkValidationsofKeys = (values) => {
    const payload = {
      appid: values.apiId,
      appkey: values.apiKey,
      epi: values.epi,
      sandbox: values.sandbox,
      storeslash: storeslash,
      authtoken: "1"
    };
    const body = {
      ...values,
      update: SettingsFlag ? "1" : "0",
      acceptedCards: values.acceptedCards.toString(),
      storeslash: storeslash,
      authtoken: "1"
    };
    Swal.fire({
      title: "Validating keys...",
      html: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    props.validateKeys(
      payload,
      (res) => {
        Swal.close();
        if (res.status === "error") {
          Swal.fire({
            title: "Error!",
            text: res.desc,
            icon: "error",
            confirmButtonText: "Close",
            allowOutsideClick: false,
            confirmButtonColor: "#093f7a"
          });
        }
        if (res.status === "success") {
          handleSaveSettings(body);
        }
      },
      (err) => {
        Swal.close();
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
          allowOutsideClick: false,
          confirmButtonColor: "#093f7a"
        });
      }
    );
  };

  React.useEffect(() => {
    if (props.data.user.settingsData) {
      if (props.data.user.settingsData.status == "success") {
        setInitialValues({
          ...props.data.user.settingsData,
          acceptedCards: props.data.user.settingsData.acceptedCards
            ? props.data.user.settingsData.acceptedCards.split(",")
            : ""
        });
      }
    }
  }, [props.data.user]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="2rem"
    >
      <Grid container alignItems="center" display="flex" marginBottom="1rem">
        <Grid xs={5} item>
          <Logo width="18%" height="18%" />
        </Grid>
        <Grid xs={7} item>
          <Typography className={classes.heading}>
            {SettingsFlag ? "Settings " : "Link Existing Account"}
          </Typography>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        innerRef={(r) => {
          formikRef.current = r;
        }}
        onSubmit={checkValidationsofKeys}
        initialValues={{ ...initialValues }}
        validationSchema={Validations.merchantSettings}
      >
        {({ values, setFieldValue, resetForm, errors, touched }) => (
          <ThemeProvider theme={FormTheme}>
            <Form>
              <Grid
                container
                rowSpacing={3}
                alignItems="center"
                // justifyContent="center"
                paddingLeft={4}
              >
                {/* adddd code */}
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-enable"
                    >
                      Enable
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="enable"
                        error={errors.enable && touched.enable}
                        helperText={touched.enable && errors.enable}
                        inputProps={{
                          className: classes.inputField
                        }}
                        labelid="label-enable"
                        variant="outlined"
                        placeholder="Select"
                        select
                      >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel className={classes.subHeading} id="label-title">
                      Title
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="title"
                        error={errors.title && touched.title}
                        helperText={touched.title && errors.title}
                        inputProps={{
                          className: classes.inputField,
                          minLength: 3,
                          maxLength: 40
                        }}
                        labelid="label-title"
                        variant="outlined"
                        placeholder="Title"
                        onChange={(e) => {
                          if (
                            /^[A-Za-z0-9\s.-]+$/.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setFieldValue("title", e.target.value);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel className={classes.subHeading} id="label-apiId">
                      APP ID
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="apiId"
                        id="apiId"
                        error={errors.apiId && touched.apiId}
                        helperText={touched.apiId && errors.apiId}
                        inputProps={{
                          className: classes.inputField
                        }}
                        // disabled={SettingsFlag}
                        labelid="label-apiId"
                        variant="outlined"
                        placeholder="APP ID"
                        type="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword.value &&
                              showPassword.fieldId == "apiId" ? (
                                <VisibilityOff
                                  onClick={() => showPasswordFun("apiId")}
                                />
                              ) : (
                                <Visibility
                                  onClick={() => showPasswordFun("apiId")}
                                />
                              )}
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-apiKey"
                    >
                      APP KEY
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="apiKey"
                        id="apiKey"
                        error={errors.apiKey && touched.apiKey}
                        helperText={touched.apiKey && errors.apiKey}
                        inputProps={{
                          className: classes.inputField
                        }}
                        // disabled={SettingsFlag}
                        labelid="label-apiKey"
                        variant="outlined"
                        placeholder="APP KEY"
                        type="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword.value &&
                              showPassword.fieldId == "apiKey" ? (
                                <VisibilityOff
                                  onClick={() => showPasswordFun("apiKey")}
                                />
                              ) : (
                                <Visibility
                                  onClick={() => showPasswordFun("apiKey")}
                                />
                              )}
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel className={classes.subHeading} id="label-epi">
                      EPI
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="epi"
                        id="epi"
                        inputProps={{
                          className: classes.inputField
                        }}
                        // disabled={SettingsFlag}
                        error={errors.epi && touched.epi}
                        helperText={touched.epi && errors.epi}
                        labelid="label-epi"
                        variant="outlined"
                        placeholder="EPI"
                        type="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword.value &&
                              showPassword.fieldId == "epi" ? (
                                <VisibilityOff
                                  onClick={() => showPasswordFun("epi")}
                                />
                              ) : (
                                <Visibility
                                  onClick={() => showPasswordFun("epi")}
                                />
                              )}
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-authToken"
                    >
                      Auth Token
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="authToken"
                        id="authToken"
                        inputProps={{
                          className: classes.inputField
                        }}
                        // disabled={SettingsFlag}
                        error={errors.authToken && touched.authToken}
                        helperText={touched.authToken && errors.authToken}
                        labelid="label-authToken"
                        variant="outlined"
                        placeholder="Auth Token"
                        type="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ display: "flex" }}>
                                <Tooltip
                                  title="Contact your ISO for auth token."
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                                {showPassword.value &&
                                showPassword.fieldId == "authToken" ? (
                                  <VisibilityOff
                                    onClick={() => showPasswordFun("authToken")}
                                  />
                                ) : (
                                  <Visibility
                                    onClick={() => showPasswordFun("authToken")}
                                  />
                                )}
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid> */}
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-sandbox"
                    >
                      Sandbox
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="sandbox"
                        error={errors.sandbox && touched.sandbox}
                        helperText={touched.sandbox && errors.sandbox}
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-sandbox"
                        variant="outlined"
                        placeholder="Select"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="Set No if Production Keys are set OR Set Yes if Sandbox Keys are set then Live payments will not be taken."
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel className={classes.subHeading} id="label-vault">
                      Use Valor VAULT
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="vault"
                        error={errors.vault && touched.vault}
                        helperText={touched.vault && errors.vault}
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-vault"
                        variant="outlined"
                        placeholder="Select"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="Allow customers to securely save their card for faster checkouts in future."
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-paymentMethod"
                    >
                      Payment Method
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="paymentMethod"
                        error={errors.paymentMethod && touched.paymentMethod}
                        helperText={
                          touched.paymentMethod && errors.paymentMethod
                        }
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-paymentMethod"
                        variant="outlined"
                      >
                        <MenuItem value="authorize">Auth Only</MenuItem>
                        <MenuItem value="authorize_capture">Sale</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-surchargeMode"
                    >
                      Surcharge Mode
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="surchargeMode"
                        error={errors.surchargeMode && touched.surchargeMode}
                        helperText={
                          touched.surchargeMode && errors.surchargeMode
                        }
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-surchargeMode"
                        variant="outlined"
                        placeholder="Select"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="Set YES only if you want all transactions to be fall on surcharge mode, Merchant must have got an Surcharge MID in order to work."
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      >
                        <MenuItem value="1">Enable</MenuItem>
                        <MenuItem value="0">Disable</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-surchargeType"
                    >
                      Surcharge Type
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="surchargeType"
                        error={errors.surchargeType && touched.surchargeType}
                        disabled={values.surchargeMode == 0}
                        helperText={
                          touched.surchargeType && errors.surchargeType
                        }
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-surchargeType"
                        variant="outlined"
                        placeholder="Select"
                      >
                        <MenuItem value="flatrate">Flat Rate</MenuItem>
                        <MenuItem value="percentage">Surcharge %</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-surchargeLabel"
                    >
                      Surcharge Label
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="surchargeLabel"
                        disabled={values.surchargeMode == 0}
                        inputProps={{
                          className: classes.inputField
                        }}
                        error={errors.surchargeLabel && touched.surchargeLabel}
                        helperText={
                          touched.surchargeLabel && errors.surchargeLabel
                        }
                        labelid="label-surchargeLabel"
                        variant="outlined"
                        placeholder="Surcharge Label"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-surchargePercent"
                    >
                      Surcharge %
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="surchargePercent"
                        error={
                          errors.surchargePercent && touched.surchargePercent
                        }
                        disabled={
                          values.surchargeMode == 0 ||
                          values.surchargeType != "percentage"
                        }
                        helperText={
                          touched.surchargePercent && errors.surchargePercent
                        }
                        inputProps={{
                          className: classes.inputField
                        }}
                        labelid="label-surchargePercent"
                        variant="outlined"
                        placeholder="Surcharge %"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="Percentage will apply only on enabling on surcharge Indicator to true and Surcharge type is set fo Surcharge %"
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-flatRate"
                    >
                      Flat Rate
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="flatRate"
                        error={errors.flatRate && touched.flatRate}
                        disabled={
                          values.surchargeMode == 0 ||
                          values.surchargeType != "flatrate"
                        }
                        helperText={touched.flatRate && errors.flatRate}
                        inputProps={{
                          className: classes.inputField
                        }}
                        labelid="label-flatRate"
                        variant="outlined"
                        placeholder="Flat Rate"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="Flat rate will apply only on if Enable surcharge mode is true and Surcharge type is set to Flat Rate"
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel className={classes.subHeading} id="label-avs">
                      AVS
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        fullWidth
                        name="avs"
                        error={errors.avs && touched.avs}
                        helperText={touched.avs && errors.avs}
                        inputProps={{
                          className: classes.inputField
                        }}
                        select
                        labelid="label-avs"
                        variant="outlined"
                        placeholder="Select"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ display: "flex", marginRight: "12px" }}
                              >
                                <Tooltip
                                  title="The address verification service will add a text field to the checkout page based on the above option."
                                  placement="top-start"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      >
                        <MenuItem value="none">None</MenuItem>
                        <MenuItem value="zip">Zip Only</MenuItem>
                        <MenuItem value="address">Address Only</MenuItem>
                        <MenuItem value="zipandaddress">Zip & Address</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  item
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "start"
                    }}
                    xs={4}
                    item
                  >
                    <InputLabel
                      className={classes.subHeading}
                      id="label-acceptedCards"
                    >
                      Accepted Cards
                      <span className={classes.requiredField}>*</span>
                    </InputLabel>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        fullWidth
                        name="acceptedCards"
                        error={errors.acceptedCards && touched.acceptedCards}
                        helperText={
                          touched.acceptedCards && errors.acceptedCards
                        }
                        inputProps={{
                          className: classes.inputField,
                          size: 6
                        }}
                        style={{ overflowY: "hidden" }}
                        multiple={true}
                        native={true}
                        labelid="label-acceptedCards"
                        variant="outlined"
                      >
                        {cards.map((name) => (
                          <option key={name.value} value={name.value}>
                            {name.name}
                          </option>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorMessage name="acceptedCards">
                      {(msg) => (
                        <Typography className={classes.FieldError}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                item
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  margin: "3rem 0 0 0"
                }}
                spacing={3}
              >
                <Box mr={2}>
                  <Button
                    className={classes.backBtn}
                    style={{ minWidth: "160px" }}
                    onClick={() => navigate(-1)}
                    variant="contained"
                  >
                    Back
                  </Button>
                </Box>
                {/* <Box mr={2}>
                  <Button
                    onClick={() => {
                      resetForm();
                    }}
                    variant="contained"
                    className={classes.clearBtn}
                  >
                    Reset
                  </Button>
                </Box> */}
                <Box>
                  <Button
                    type="submit"
                    className={classes.mainBtn}
                    style={{ minWidth: "160px" }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Form>
          </ThemeProvider>
        )}
      </Formik>
    </Grid>
  );
};

ExistingMerchant.propTypes = {
  validateKeys: PropTypes.func,
  getSettingDetails: PropTypes.func,
  updateSettingDetails: PropTypes.func
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {
  validateKeys,
  getSettingDetails,
  updateSettingDetails
})(ExistingMerchant);
