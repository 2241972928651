// LOADER
export const LOADER = "LOADER";

// LOADER
export const SEARCH_DATE = "SEARCH_DATE";
export const PAGINATION = "PAGINATION";

// MERCHANT Action type
export const UPDATE_MERCHANT_SETTINGS_API_CALL =
  "UPDATE_MERCHANT_SETTINGS_API_CALL";
export const UPDATE_MERCHANT_SETTINGS_API_CALL_SUCCESS =
  "UPDATE_MERCHANT_SETTINGS_API_CALL_SUCCESS";
export const UPDATE_MERCHANT_SETTINGS_API_CALL_ERROR =
  "UPDATE_MERCHANT_SETTINGS_API_CALL_ERROR";

export const LINK_MERCHANT_API_CALL = "LINK_MERCHANT_API_CALL";
export const LINK_MERCHANT_API_CALL_SUCCESS = "LINK_MERCHANT_API_CALL_SUCCESS";
export const LINK_MERCHANT_API_CALL_ERROR = "LINK_MERCHANT_API_CALL_ERROR";

export const GET_SETTINGS_API_CALL = "GET_SETTINGS_API_CALL";
export const GET_SETTINGS_API_CALL_SUCCESS = "GET_SETTINGS_API_CALL_SUCCESS";
export const GET_SETTINGS_API_CALL_ERROR = "GET_SETTINGS_API_CALL_ERROR";

export const CHANGE_SETTINGS_API_CALL = "CHANGE_SETTINGS_API_CALL";
export const CHANGE_SETTINGS_API_CALL_SUCCESS =
  "CHANGE_SETTINGS_API_CALL_SUCCESS";
export const CHANGE_SETTINGS_API_CALL_ERROR = "CHANGE_SETTINGS_API_CALL_ERROR";

// PAYMENT Action type
export const CREATE_NEW_PAYMENT_API_CALL = "CREATE_NEW_PAYMENT_API_CALL";
export const CREATE_NEW_PAYMENT_API_CALL_SUCCESS =
  "CREATE_NEW_PAYMENT_API_CALL_SUCCESS";
export const CREATE_NEW_PAYMENT_API_CALL_ERROR =
  "CREATE_NEW_PAYMENT_API_CALL_ERROR";

export const REFUND_ORDER_API_CALL = "REFUND_ORDER_API_CALL";
export const REFUND_ORDER_API_CALL_SUCCESS = "REFUND_ORDER_API_CALL_SUCCESS";
export const REFUND_ORDER_API_CALL_ERROR = "REFUND_ORDER_API_CALL_ERROR";

export const STORED_CARDS_API_CALL = "STORED_CARDS_API_CALL";
export const STORED_CARDS_API_CALL_SUCCESS = "STORED_CARDS_API_CALL_SUCCESS";
export const STORED_CARDS_API_CALL_ERROR = "STORED_CARDS_API_CALL_ERROR";

export const REFUND_ORDER_DETAILS_API_CALL = "REFUND_ORDER_DETAILS_API_CALL";
export const REFUND_ORDER_DETAILS_API_CALL_SUCCESS =
  "REFUND_ORDER_DETAILS_API_CALL_SUCCESS";
export const REFUND_ORDER_DETAILS_API_CALL_ERROR =
  "REFUND_ORDER_DETAILS_API_CALL_ERROR";

export const REFUND_PAYMENT_API_CALL = "REFUND_PAYMENT_API_CALL";
export const REFUND_PAYMENT_API_CALL_SUCCESS =
  "REFUND_PAYMENT_API_CALL_SUCCESS";
export const REFUND_PAYMENT_API_CALL_ERROR = "REFUND_PAYMENT_API_CALL_ERROR";

export const CHECKOUT_API_CALL = "CHECKOUT_API_CALL";
export const CHECKOUT_API_CALL_SUCCESS = "CHECKOUT_API_CALL_SUCCESS";
export const CHECKOUT_API_CALL_ERROR = "CHECKOUT_API_CALL_ERROR";

export const VALIDATE_KEYS_API_CALL = "VALIDATE_KEYS_API_CALL";
export const VALIDATE_KEYS_API_CALL_SUCCESS = "VALIDATE_KEYS_API_CALL_SUCCESS";
export const VALIDATE_KEYS_API_CALL_ERROR = "VALIDATE_KEYS_API_CALL_ERROR";
