/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import { array, mixed, object, string, date, number, boolean } from "yup";
let titleRegex = /^[A-Za-z\s.-]+$/;
export default {
  merchantSettings: object({
    enable: string("Please select a value").required("Field is required."),
    title: string("Title is required")
      .required("Enter title between 3 to 40 characters")
      .min(3, "Title should be minimum 3 characters!")
      .max(40, "Title should be maximum 40 characters!"),
    apiId: string("APP ID is required").required("APP ID is required."),
    apiKey: string("APP KEY is required").required("APP KEY is required"),
    epi: string("EPI is required").required("EPI is required."),
    // authToken: string("Auth token is required").required(
    //   "Auth Token is required."
    // ),
    sandbox: string("Please select a value").required("Field is required."),
    paymentMethod: string("Please select a value").required(
      "Payment method is required."
    ),
    surchargeMode: string("Please select a value").required(
      "Field is required."
    ),
    surchargeType: string("Please select a value").when("surchargeMode", {
      is: "1",
      then: () =>
        yup.string("Please select a value").required("Field is required.")
    }),
    avs: string("Please select a value").required("Field is required."),
    surchargeLabel: string("Surcharge label is required").when(
      "surchargeMode",
      {
        is: "1",
        then: () =>
          yup
            .string("Surcharge label is required")
            .nullable()
            .required("Field is required.")
      }
    ),
    surchargePercent: string("Surcharge percent is required").when(
      ["surchargeMode", "surchargeType"],
      {
        is: (surchargeMode, surchargeType) =>
          surchargeMode == 1 && surchargeType == "percentage",
        then: () =>
          yup
            .string("Surcharge percent is required")
            .nullable()
            .required("Field is required.")
      }
    ),
    flatRate: string("Flat rate is required").when(
      ["surchargeMode", "surchargeType"],
      {
        is: (surchargeMode, surchargeType) =>
          surchargeMode == 1 && surchargeType == "flatrate",
        then: () =>
          yup
            .string("Flat rate is required")
            .nullable()
            .required("Field is required.")
      }
    ),
    acceptedCards: array("Please select a card")
      .of(yup.string().required("Please select atleast 1 card"))
      .min(1, "Please select atleast 1 card")
      .required()
  })
};
