import qs from "qs";
import axios from "axios";
import Swal from "sweetalert2";

function checkHTTPStatus(status) {
  if (status === 401) {
    localStorage.setItem("sessionExpired", true);
  }
}

const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 5000);
  return controller;
};

export const post = async ({ url, success, failure, dispatch, body }) => {
  let data;
  const bodyValue = body;
  try {
    let mainBody = "";
    if (bodyValue === "") {
      mainBody = "";  
    } else {
      mainBody = JSON.stringify(bodyValue);
    }
    const customHeaders = {
      "Content-Type": "application/json"
    };
    // const auth = localStorage.getItem("token");
    // if (auth && payment === undefined) {
    //   customHeaders.authorization = auth;
    // }
    const res = await fetch(url, {
      signal: Timeout(30).signal,
      method: "POST",
      headers: customHeaders,
      body: mainBody,
      withCredentials: true
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    let error;
    if (e.name == "AbortError") {
      error = "Connection request timeout. Please refresh the page!";
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
        allowOutsideClick: false,
        confirmButtonColor: "#093f7a"
      });
    } else {
      error = data;
    }
    if (failure) {
      dispatch({ type: failure, error });
    }
  }
};

export const get = async ({ url, success, failure, dispatch, body }) => {
  let data;
  let bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json"
    };
    if (body === "default" || !body) {
      bodyValue = "";
    }
    let finalurl = "";
    if (bodyValue === "") {
      finalurl = `${url}`;
    } else {
      finalurl = `${url}?${qs.stringify(bodyValue)}`;
    }
    const res = await fetch(finalurl, {
      signal: Timeout(30).signal,
      method: "get",
      headers: customHeaders,
      withCredentials: true
    });
    data = await res.json();
    data.initialCall = true;
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    let error;
    if (e.name == "AbortError") {
      error = "Connection request timeout. Please refresh the page!";
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
        allowOutsideClick: false,
        confirmButtonColor: "#093f7a"
      });
    } else {
      error = data;
    }
    if (failure) {
      dispatch({ type: failure, error });
    }
  }
};

export const put = async ({ url, success, failure, dispatch, body }) => {
  let data;
  const bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json"
    };
    // const auth = localStorage.getItem("token");
    // if (auth) {
    //   customHeaders.authorization = auth;
    // }
    const res = await fetch(url, {
      method: "PUT",
      headers: customHeaders,
      body: JSON.stringify(bodyValue),
      withCredentials: true
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const del = async ({ url, success, failure, dispatch, body }) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json"
    };
    // const auth = localStorage.getItem("token");
    // if (auth) {
    //   customHeaders.authorization = auth;
    // }
    const res = await fetch(url, {
      method: "DELETE",
      headers: customHeaders,
      body: JSON.stringify(body),
      withCredentials: true
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};
