import * as types from "../Actions/ActionTypes";
import initialState from "./InitialState";

const payment = (state = initialState.app.paymentStore, action) => {
  switch (action.type) {
    // search details
    case types.SEARCH_DATE:
      return {
        ...state,
        searchDetails: action.data,
        loading: false
      };
    case types.PAGINATION:
      return {
        ...state,
        pageDetails: action.data,
        loading: false
      };
    // new payment
    case types.CREATE_NEW_PAYMENT_API_CALL:
      return {
        ...state,
        newPayment: false,
        loading: true,
        error: false
      };
    case types.CREATE_NEW_PAYMENT_API_CALL_SUCCESS:
      return {
        ...state,
        newPayment: action.data,
        loading: false,
        error: false
      };
    case types.CREATE_NEW_PAYMENT_API_CALL_ERROR:
      return { ...state, newPayment: false, loading: false, error: true };
    // refund order
    case types.REFUND_ORDER_API_CALL:
      return {
        ...state,
        refundOrder: false,
        loading: true,
        error: false
      };
    case types.REFUND_ORDER_API_CALL_SUCCESS:
      return {
        ...state,
        refundOrder: action.data,
        loading: false,
        error: false
      };
    case types.REFUND_ORDER_API_CALL_ERROR:
      return { ...state, refundOrder: false, loading: false, error: true };
    // stored Cards
    case types.STORED_CARDS_API_CALL:
      return {
        ...state,
        storedCards: false,
        loading: true,
        error: false
      };
    case types.STORED_CARDS_API_CALL_SUCCESS:
      return {
        ...state,
        storedCards: action.data,
        loading: false,
        error: false
      };
    case types.STORED_CARDS_API_CALL_ERROR:
      return { ...state, storedCards: false, loading: false, error: true };
    // refund order details
    case types.REFUND_ORDER_DETAILS_API_CALL:
      return {
        ...state,
        refundOrderbyId: false,
        loading: true,
        error: false
      };
    case types.REFUND_ORDER_DETAILS_API_CALL_SUCCESS:
      return {
        ...state,
        refundOrderbyId: action.data,
        loading: false,
        error: false
      };
    case types.REFUND_ORDER_DETAILS_API_CALL_ERROR:
      return { ...state, refundOrderbyId: false, loading: false, error: true };

    default:
      return state;
  }
};

export default payment;
