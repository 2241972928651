import * as urls from "../Utils/urls";
import { post, put, get } from "./httpMethods";

export const validateKeys =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(
        urls.VALIDATE_KEYS_URL.replace(":d", payload.storeslash),
        payload
      );

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const sendOTP =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(
        urls.SEND_OTP_URL.replace(":d", payload.storeslash),
        payload
      );

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateSettingDetails =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(
        urls.UPDATE_LINK_SETTINGS_URL.replace(":d", payload.storeslash),
        payload
      );

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const refundPayment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(
        urls.REFUND_PAYMENT_URL.replace(":d", payload.storeslash),
        payload
      );

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
